<template>
  <section class="liquid-stake">
    <h1 class="container-title text-48">
      {{ $t('home.title') }}
    </h1>
    <div class="container-subtitle text-16">
      {{ $t('home.pageDesc') }}
    </div>

    <stake-card />
  </section>
</template>
